import mediumZoom from "medium-zoom"
import Turbolinks from "turbolinks"
import fitvids from "fitvids"
import "lazysizes"
import "./index.css"

Turbolinks.start()
document.addEventListener("turbolinks:load", onLoad)
document.addEventListener("turbolinks:before-cache", onBeforeCache)

let projectNav = null

/**
 * Handle every page load, both browser and Turbolinks.
 */
function onLoad(e) {
  const pathname = new URL(e.data.url).pathname

  openExternalLinksInNewTab()
  fitvids()

  projectNav = initProjectNavigation()

  if (pathname.startsWith("/work/")) {
    initLightbox()
    initFavicon()
  }
}

function openExternalLinksInNewTab() {
  Array.from(document.links).forEach((el) => {
    if (el.hostname === window.location.hostname) {
      return
    }
    el.setAttribute("target", "_blank")
    el.setAttribute("rel", "noopener noreferrer")
  })
}

/**
 * Reset all temporary state on the page, in preparation for the page to be
 * cached by Turbolinks.
 */
function onBeforeCache() {
  if (projectNav) {
    projectNav.reset()
  }
}

function initLightbox() {
  const $images = qa(`.TextContent img,.ScatterGallery img`)
  mediumZoom($images, {
    margin: 48,
    scrollOffset: 80,
  })
}

function initFavicon() {
  const $favicon = q(".js-favicon")
  if (!$favicon) {
    return
  }
  $favicon.href = getFaviconUrl(document.body.dataset.category)
}

const defaultCategory = "*"
const isFilteringClass = "js-filterLinkContainer--isFiltering"

function initProjectNavigation() {
  const $projectLinks = qa(".ProjectRow,.ProjectRow-nested")
  const $navigationLinks = qa(".js-filterLink[data-category]")
  const $navigationLinksContainer = q(".js-filterLinkContainer")
  const $favicon = q(".js-favicon")

  const $activeLink = $navigationLinks.find((el) =>
    el.hasAttribute("data-active"),
  )
  let currentCategory =
    ($activeLink && $activeLink.dataset.category) || defaultCategory

  $navigationLinks.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault()
      const nextCategory = e.target.dataset.category
      if (currentCategory === nextCategory) {
        return
      }
      currentCategory = nextCategory
      render()
    })
  })

  return {
    reset() {
      currentCategory = defaultCategory
      render()
    },
  }

  function render() {
    const currentCategories = currentCategory.split(",")

    $navigationLinks.forEach((el) => {
      if (isInCurrentFilter(el, currentCategories)) {
        el.setAttribute("data-active", "")
      } else {
        el.removeAttribute("data-active")
      }
    })

    $projectLinks.forEach((el) => {
      el.classList.remove("v-hide")
    })
    $navigationLinksContainer.classList.remove(isFilteringClass)
    $favicon.href = "/assets/favicon.ico"

    if (currentCategories.includes(defaultCategory)) {
      return
    }

    $navigationLinksContainer.classList.add(isFilteringClass)
    $projectLinks.forEach((link) => {
      if (!isInCurrentFilter(link, currentCategories)) {
        link.classList.add("v-hide")
      }
    })
    $favicon.href = getFaviconUrl(currentCategories)
  }

  function isInCurrentFilter(el, categories) {
    const linkCategories = (el.dataset.category || "").split(",")
    return intersects(categories, linkCategories)
  }
}

function getFaviconUrl(categoryString) {
  if (categoryString.includes("exhibition")) {
    return "/assets/favicon-curation.ico"
  } else if (categoryString.includes("editing")) {
    return "/assets/favicon-editing.ico"
  }
  return "/assets/favicon.ico"
}

function q(selector) {
  return document.querySelector(selector)
}

function qa(selector) {
  return Array.from(document.querySelectorAll(selector))
}

function intersects(arr1, arr2) {
  return arr1.some((val) => arr2.includes(val))
}
